<template>
  <div>
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <label>UUID</label>
      <CInput
        type="text"
        :value="form.uuid"
        readonly        
        class="validate_number"
      />
      <label class="required-field">Name</label>
      <CInput v-model.trim="form.name" placeholder="Enter name" />
      <span v-if="$v.form.name.$error" class="text-danger"
        >Please enter valid name</span
      >
      <span v-if="nameError" class="text-danger">{{ nameError }}</span>
      <div>
        <label class="required-field">
          Icon Name ( please check
          <a target="_blank" href="https://fontawesome.com/v4.7.0/icons/">
            <b>
              <i>
                <u>Font Awesome</u>
              </i>
            </b>
          </a>
          to get correct icon)
        </label>
      </div>
      <CInput
        v-model="form.icon_name"
        placeholder="Enter icon name like (fa fa-tools)"
      />
      <span v-if="$v.form.icon_name.$error" class="text-danger"
        >Icon name is required</span
      >
      <template #header>
        <h6 class="modal-title">Near by Amenities</h6>
        <CButtonClose @click="onCloseModal()" class="text-white" />
      </template>
      <template #footer>
        <CButton @click.prevent="saveModal" color="success">Save</CButton>
        <CButton @click="onCloseModal()" color="danger">Cancel</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import {
  postNearbyAmenity,
  getNearbyAmenity,
  putNearbyAmenity,
} from "@/api/nearbyAmenities";
import { required, helpers } from "vuelidate/lib/validators";
const isName = helpers.regex("custom", /^([a-zA-Z ]){2,30}$/);

export default {
  name: "NearbyAmenityEditForm",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    onHideModal: {
      type: Function,
    },
  },
  created() {
    this.showModal = this.$props.modalShow;
    if (this.$props.id) {
      getNearbyAmenity(this.$props.id)
        .then((res) => {
          this.form = res.data.data;
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  },
  data: () => {
    return {
      showModal: false,
      form: {
        name: "",
        icon_name: "",
      },
      nameError: "",
      file: "",
    };
  },
  methods: {
    onReset() {
      this.form.length = "";
    },
    changeModal() {
      if (!this.showModal) {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      this.showModal = false;
      console.log("close");
      this.$props.onHideModal();
    },
    saveModal() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      if (this.$props.id) {
        putNearbyAmenity(this.$props.id, this.form)
          .then((res) => {
            this.$emit("saveNearbyAmenities", res.data.data, this.$props.id);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.onCloseModal();
          })
          .catch((error) => {
            console.log(error, "error");
            this.nameError = error.response.data.data.name[0];
          });
      } else {
        postNearbyAmenity(this.form)
          .then((res) => {
            console.log(res);
            this.$emit("saveNearbyAmenities", res.data.data);
            this.$notify({
              group: "notify",
              type: "success",
              text: res.data.message,
            });
            this.onCloseModal();
          })
          .catch((error) => {
            console.log(error, "error");
            this.nameError = error.response.data.data.name[0];
          });
      }
    },
  },
  validations: {
    form: {
      name: {
        required,
        isName,
      },
      icon_name: {
        required,
      },
    },
  },
};
</script>
